import React, { useMemo } from "react"
import { Box, Typography } from "@mui/material"
import MaterialReactTable from "material-react-table"
import {roundToTwoDecimalPlaces, roundToThreeDecimalPlaces} from "../utils/dataFormat.js"

function SingleTripDataSummary({ data }) {
    const getStatus = ({ row }) => (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    backgroundColor: `${row?.original?.status?.color}`,
                    p: 1,
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    ml: 0.5,
                    mr: 1.5,
                }}
            />
            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                }}
            >
                {row?.original?.status?.text}
            </Typography>
        </Box>
    )
    const columns = useMemo(
        () => [
            {
                size: 160,
                accessorKey: "status",
                header: "",
                Cell: getStatus,
            },
            {
                size: 160,
                accessorKey: "cost",
                header: "Cost",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(Number(row?.original?.cost ?? 0)) }`,
            },
            {
                size: 160,
                accessorKey: "distance",
                header: "Distance (km)",
                Cell: ({ row }) =>
                    `${roundToThreeDecimalPlaces(
                        Number(row?.original?.distance ?? 0) / 1000
                    )}`,
            },
            {
                size: 160,
                accessorKey: "waiting_time",
                header: "Waiting Time",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        Number(row?.original?.waiting_time ?? 0)
                    )}`,
            },
            {
                size: 160,
                accessorKey: "waiting_cost",
                header: "Waiting Cost",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        Number(row?.original?.waiting_cost ?? 0)
                    )}`,
            },
            {
                size: 160,
                accessorKey: "total_location_update_count",
                header: "Total Location Update Count",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        Number(row?.original?.total_location_update_count ?? 0)
                    )}`,
            },
            {
                size: 160,
                accessorKey: "sent_location_update_count",
                header: "Sent Location Update Count",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        Number(row?.original?.sent_location_update_count ?? 0)
                    )}`,
            },
            {
                size: 160,
                accessorKey: "accepted_location_update_count",
                header: "Accepted Location Update Count",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        Number(row?.original?.accepted_location_update_count ?? 0)
                    )}`,
            },
        ],
        []
    )
    return (
        <Box
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <MaterialReactTable
                columns={columns}
                data={data ?? []}
                enableTopToolbar={false}
                enableColumnActions={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnResizing
                initialState={{
                    columnPinning: {
                        left: ["status"],
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                enablePinning
            />
        </Box>
    )
}
export default SingleTripDataSummary
