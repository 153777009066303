import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import Home from './pages/home';
import { StpDebugContextProvider } from './context/stpDebugContextProvider';
import BulkTrips from './pages/bulkTrips';
import SingleTrips from './pages/singleTrip';

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
        autoHideDuration={3000}
        preventDuplicate
      >
        <BrowserRouter>
          <StpDebugContextProvider>
            <Routes>
              <Route index element={<Home />} />
              <Route path='bulk-trips-analytics' element={<BulkTrips />} />
              <Route path='single-trip-analytics' element={<SingleTrips />} />
            </Routes>
          </StpDebugContextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
