import { useContext } from "react";
import { useMutation } from "react-query";
import axios from "../utils/api/axios";
import StpDebugContext from "../context/stpDebugContextProvider";
import { useSnackbar } from "notistack"

function useBulkData() {
    const { filter, setBulkData, setSingleTripData } = useContext(StpDebugContext);
    const filterValue = filter.operator_value !== "between" ? { column_field: filter.column_field, operator_value: filter.operator_value, value: filter.value.value1 } : { column_field: filter.column_field, operator_value: filter.operator_value, value: `${filter.value.value1}|${filter.value.value2}` }

    const { enqueueSnackbar } = useSnackbar()

    return useMutation((formData) =>
        axios.post(`/debugs/stp_trips`, formData, {
            params: {
                filter: JSON.stringify([filterValue]),
                per_page: -1
            }
        })
            .then((response) => response),
        {
            onSuccess: (data) => {
                setBulkData(data?.data?.data)
                setSingleTripData({})
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error[0]?.description
                const errorMessage = error.response?.data?.error?.message
                if (filter.operator_value === "between" && (filter.value.value1 === null || filter.value.value2 === null))
                    enqueueSnackbar("please enter a date", { variant: "error" })
                else if (filter.operator_value !== "between" && filter.value.value1 === null)
                    enqueueSnackbar("please enter a date", { variant: "error" })
                else enqueueSnackbar(
                    fieldError || errorMessage || "Network Error",
                    { variant: "error" }
                )
            },
        }
    )
}
export default useBulkData