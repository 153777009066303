import React, { useContext, useMemo, useRef } from "react";
import {
  AccessTime,
  ArrowLeft,
  LocationCity,
  LocationOn,
  Money,
  MyLocation,
  PriceChange,
  Straighten,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { roundToTwoDecimalPlaces } from "../utils/dataFormat";
import StpDebugContext from "../context/stpDebugContextProvider";
import AnalyticsChart from "../components/analyticsChart";
import NoDataFound from "../components/noDataFound";
import BulkTripsRequestForm from "../components/bulkTripsRequestForm";

const summary = [
  {
    name: "Original to Computed",
    color: "#9f9b94",
  },
  {
    name: "Original to Snapped",
    color: "#268690",
  },
];

function BulkTrips() {
  const { bulkData } = useContext(StpDebugContext);
  const ref = useRef();

  const navigate = useNavigate();

  const customData = useMemo(
    () => [
      {
        name: "Cost",
        icon: <PriceChange sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(item?.original?.cost ?? 0),
          computed: roundToTwoDecimalPlaces(item?.computed?.cost ?? 0),
          snapped: roundToTwoDecimalPlaces(item?.snapped?.cost ?? 0),
        })),
        average_oc_variance:
          bulkData?.average_variances?.average_oc_cost_variance,
        average_os_variance:
          bulkData?.average_variances?.average_os_cost_variance,
      },
      {
        name: "Distance",
        icon: <Straighten sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(item?.original?.distance ?? 0),
          computed: roundToTwoDecimalPlaces(item?.computed?.distance ?? 0),
          snapped: roundToTwoDecimalPlaces(item?.snapped?.distance ?? 0),
        })),
        average_oc_variance:
          bulkData?.average_variances?.average_oc_distance_variance,
        average_os_variance:
          bulkData?.average_variances?.average_os_distance_variance,
      },
      {
        name: "Waiting Time",
        icon: <AccessTime sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(item?.original?.waiting_time ?? 0),
          computed: roundToTwoDecimalPlaces(item?.computed?.waiting_time ?? 0),
          snapped: roundToTwoDecimalPlaces(item?.snapped?.waiting_time ?? 0),
        })),
        average_oc_variance:
          bulkData?.average_variances?.average_oc_waiting_time_variance,
        average_os_variance:
          bulkData?.average_variances?.average_os_waiting_time_variance,
      },
      {
        name: "Waiting Cost",
        icon: <Money sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(item?.original?.waiting_cost ?? 0),
          computed: roundToTwoDecimalPlaces(item?.computed?.waiting_cost ?? 0),
          snapped: roundToTwoDecimalPlaces(item?.snapped?.waiting_cost ?? 0),
        })),
        average_oc_variance:
          bulkData?.average_variances?.average_oc_waiting_cost_variance,
        average_os_variance:
          bulkData?.average_variances?.average_os_waiting_cost_variance,
      },
      {
        name: "Total Location Update Count",
        icon: <LocationOn sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(
            item?.original?.total_location_update_count ?? 0
          ),
          computed: roundToTwoDecimalPlaces(
            item?.computed?.total_location_update_count ?? 0
          ),
          snapped: roundToTwoDecimalPlaces(
            item?.snapped?.total_location_update_count ?? 0
          ),
        })),
        average_oc_variance:
          bulkData?.average_variances
            ?.average_oc_total_location_update_count_variance,
        average_os_variance:
          bulkData?.average_variances
            ?.average_os_total_location_update_count_variance,
      },
      {
        name: "Sent Location Update Count",
        icon: <MyLocation sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(
            item?.original?.sent_location_update_count ?? 0
          ),
          computed: roundToTwoDecimalPlaces(
            item?.computed?.sent_location_update_count ?? 0
          ),
          snapped: roundToTwoDecimalPlaces(
            item?.snapped?.sent_location_update_count ?? 0
          ),
        })),
        average_oc_variance:
          bulkData?.average_variances
            ?.average_oc_sent_location_update_count_variance,
        average_os_variance:
          bulkData?.average_variances
            ?.average_os_sent_location_update_count_variance,
      },
      {
        name: "Accepted Location Update Count",
        icon: <LocationCity sx={{ p: 1 }} />,
        data: bulkData?.ondemand_computation_response?.map((item) => ({
          date: DateTime.fromISO(item?.end_time).toFormat("ff"),
          original: roundToTwoDecimalPlaces(
            item?.original?.accepted_location_update_count ?? 0
          ),
          computed: roundToTwoDecimalPlaces(
            item?.computed?.accepted_location_update_count ?? 0
          ),
          snapped: roundToTwoDecimalPlaces(
            item?.snapped?.accepted_location_update_count ?? 0
          ),
        })),
        average_oc_variance:
          bulkData?.average_variances
            ?.average_oc_accepted_location_update_count_variance,
        average_os_variance:
          bulkData?.average_variances
            ?.average_os_accepted_location_update_count_variance,
      },
    ],
    [bulkData]
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: bulkData?.length !== 0 ? "320px" : "600px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Ride Plus Trips
        </Typography>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Analytics Tool
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <IconButton
            edge="start"
            sx={{
              mr: 1,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowLeft sx={{ fontSize: 40 }} />
          </IconButton>
          <Button
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none",
              bgcolor: "#becfa9",
              color: "black",
              ":hover": {
                bgcolor: "#becfa9",
                color: "black",
              },
              width: "12rem",
              borderRadius: 0,
              p: 1,
            }}
          >
            Bulk Analysis
          </Button>
        </Box>
        <BulkTripsRequestForm />
      </Box>
      {bulkData?.ondemand_computation_response?.length === 0 && <NoDataFound />}
      {bulkData?.ondemand_computation_response?.length > 0 && (
        <AnalyticsChart data={customData} ref={ref} summary={summary} />
      )}
    </React.Fragment>
  );
}

export default BulkTrips;
