// display only 2 decimal places without rounding
export const roundToTwoDecimalPlaces = (number = 0) => {
    const numString = number.toString();
    const decimalIndex = numString.indexOf(".");
    if (decimalIndex === -1) {
      return number;
    }
    const num = numString.slice(0, decimalIndex + 3);
    return Number(num);
  }; 
  // display only 3 decimal places without rounding
  export const roundToThreeDecimalPlaces = (number = 0) => {
    const numString = number.toString();
    const decimalIndex = numString.indexOf(".");
    if (decimalIndex === -1) {
      return number;
    }
    const num = numString.slice(0, decimalIndex + 4);
    return Number(num);
  };
  // display only 4 decimal places without rounding
  export const roundToFourDecimalPlaces = (number = 0) => {
    const numString = number.toString();
    const decimalIndex = numString.indexOf(".");
    if (decimalIndex === -1) {
      return number;
    }
    const num = numString.slice(0, decimalIndex + 5);
    return Number(num);
  };