import { Rectangle } from "@mui/icons-material";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { roundToFourDecimalPlaces } from "../utils/dataFormat";
import CustomChart from "./customChart";

function AnalyticsChart(props) {
  const { data, ref, summary } = props;

  return (
    <Grid container spacing={5} sx={{ mb: 6 }}>
      {data?.map((element) => (
        <Grid key={element.name} item xs={12} sx={6} md={6} lg={6} xl={6}>
          <Box
            sx={{
              boxShadow: "0px 7px 5px -2px black",
            }}
            ref={ref}
          >
            <Grid container>
              <Grid item xs={12} sm={9} md={9} lg={9}>
                <Paper id="bulk-analysis-chart-paper" elevation={0}>
                  <Grid container justifyContent="flex-start">
                    <Stack
                      direction="row"
                      sx={{
                        bgcolor: "#becfa9",
                        mt: -2,
                        px: 1,
                      }}
                    >
                      {element.icon}
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: "medium",
                          p: 1,
                        }}
                      >
                        {element.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <CustomChart data={element?.data} />
                </Paper>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderRightWidth: 2,
                  mt: {xs: 0, sm: 0, md: 5},
                  mb: {xs: 0, sm: 0, md: 6}
                }}
              />
              <Grid item xs={12} sm={2.5} md={2.5} lg={2.5}>
                <Box
                  sx={{
                    mt: { xs: 10, sm: 4 },
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                      p: 1,
                      mb: -1,
                      ml: 1,
                    }}
                  >
                    Average Variance
                  </Typography>
                  {summary?.map((item) => (
                    <Box
                      key={item.name}
                      sx={{
                        display: "flex",
                        m: 1,
                      }}
                    >
                      <Rectangle sx={{ color: item.color, m: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: 14,
                            fontWeight: "medium",
                          }}
                        >
                          {item.name.includes("Computed")
                            ? roundToFourDecimalPlaces(
                                element?.average_oc_variance
                              )
                            : roundToFourDecimalPlaces(
                                element?.average_os_variance
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
export default AnalyticsChart;
