import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const CustomChart = (props) => {
  const [data, setData] = useState(props.data);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [bottomDomain, setBottomDomain] = useState(null);
  const [topDomain, setTopDomain] = useState(null);
  const [displayZoomButton, setDisplayZoomButton] = useState(true);
  const [state, setState] = useState({
    original: true,
    computed: true,
    snapped: true,
  });
  const { original, computed, snapped } = state;

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const zoom = () => {
    setDisplayZoomButton(false);
    if (Number(refAreaLeft) === Number(refAreaRight) || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }
    const newData = data.filter(
      (d) => d.date >= refAreaLeft && d.date <= refAreaRight
    );

    setRefAreaLeft("");
    setRefAreaRight("");
    setLeft(refAreaLeft);
    setRight(refAreaRight);
    setData(newData);
  };

  const zoomOut = () => {
    setDisplayZoomButton(true);
    setData(props.data);
    setRefAreaLeft("");
    setRefAreaRight("");
    setLeft("dataMin");
    setRight("dataMax");
  };

  useEffect(() => {
    const notSelected = (type) =>
      props?.data?.map((item) =>
        Object.keys(item)
          .filter((key) => !key.includes(`${type}`))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: item[key],
            });
          }, {})
      );
    const selected = (type) =>
      props?.data?.map((item) =>
        Object.keys(item)
          .filter((key) => key.includes(`${type}`))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              date: item["date"],
              [key]: item[key],
            });
          }, {})
      );
    if (computed && snapped) {
      setData(notSelected("original"));
    }
    if (original && snapped) {
      setData(notSelected("computed"));
    }
    if (original && computed) {
      setData(notSelected("snapped"));
    }
    if (original && !snapped && !computed) {
      setData(selected("original"));
    }
    if (computed && !snapped && !original) {
      setData(selected("computed"));
    }
    if (snapped && !original && !computed) {
      setData(selected("snapped"));
    }
    if (original && computed && snapped) {
      setData(props.data);
    }
    if (!original && !computed && !snapped) {
      setData([]);
    }
  }, [computed, original, props.data, snapped]);

  useEffect(() => {
    // Calculate the maximum value from the data array
    const originalValues = data?.map((d) => d.original);
    const computedValues = data?.map((d) => d.computed);
    const snappedValues = data?.map((d) => d.snapped);
    const max = data && Math.max(
      ...originalValues,
      ...computedValues,
      ...snappedValues
    );

    // Set the domain for Y-axis based on the maximum value
    setBottomDomain(0);
    setTopDomain(max);
  }, [data]);

  return (
    <Box
      sx={{
        mt: 1,
        mb: 4,
        width: "95%",
        height: { sm: 400, xs: 300 },
        mr: { sm: 0, xs: 1 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" },
          ml: 2,
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={zoomOut}
          sx={{ textTransform: "none", mr: 2, height: "30px", width: "100px" }}
          disabled={displayZoomButton}
        >
          Zoom Out
        </Button>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={original}
                    onChange={handleChange}
                    name="original"
                    sx={{
                      "&.Mui-checked": {
                        color: "green",
                      },
                    }}
                  />
                }
                label="Original"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={computed}
                    onChange={handleChange}
                    name="computed"
                    sx={{
                      "&.Mui-checked": {
                        color: "#fdbc3d",
                      },
                    }}
                  />
                }
                label="Computed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={snapped}
                    onChange={handleChange}
                    name="snapped"
                    sx={{
                      "&.Mui-checked": {
                        color: "#ed3f3c",
                      },
                    }}
                  />
                }
                label="Snapped"
              />
            </Box>
          </FormGroup>
        </FormControl>
      </Box>
      <ResponsiveContainer>
        <LineChart
          data={data}
          onMouseDown={(e) => setRefAreaLeft(e?.activeLabel)}
          onMouseMove={(e) => refAreaLeft && setRefAreaRight(e?.activeLabel)}
          onMouseUp={zoom}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 50,
          }}
        >
          <XAxis dataKey="date" domain={[left, right]} />
          <YAxis domain={[bottomDomain, topDomain]} />
          <Tooltip />
          <Line type="monotone" dataKey="original" stroke="green" />
          <Line type="natural" dataKey="computed" stroke="#fdbc3d" />
          <Line type="natural" dataKey="snapped" stroke="#ed3f3c" />
          {refAreaLeft && refAreaRight && (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomChart;
