import React, { useContext, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CustomMapContainer from "../components/mapContainer";
import { ArrowLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StpDebugContext from "../context/stpDebugContextProvider";
import SingleTripDataSummary from "../components/singleTripDataSummary";
import SingleTripRequestForm from "../components/singleTripRequestForm";

function SingleTrips() {
  const { singleTripData } = useContext(StpDebugContext);
  const [state, setState] = useState({
    original: true,
    computed: true,
    snapped: true,
  });
  const navigate = useNavigate();
  const { original, computed, snapped } = state;

  const data = useMemo(
    () => [
      {
        name: "original",
        poly_line: singleTripData?.original?.poly_line ?? "",
        color: original ? "lime" : "#1C00ff00",
        status: original,
      },
      {
        name: "computed",
        poly_line: singleTripData?.computed?.poly_line ?? "",
        color: computed ? "#fdbc3d" : "#1C00ff00",
        status: computed,
      },
      {
        name: "snapped",
        poly_line: singleTripData?.snapped?.poly_line ?? "",
        color: snapped ? "#ed3f3c" : "#1C00ff00",
        status: snapped,
      },
    ],
    [computed, original, singleTripData, snapped]
  );

  const summary = useMemo(
    () => [
      {
        status: { text: "Original", color: "lime" },
        cost: singleTripData?.original?.cost,
        distance: singleTripData?.original?.distance,
        waiting_time: singleTripData?.original?.waiting_time,
        waiting_cost: singleTripData?.original?.waiting_cost,
        total_location_update_count:
          singleTripData?.original?.total_location_update_count,
        sent_location_update_count:
          singleTripData?.original?.sent_location_update_count,
        accepted_location_update_count:
          singleTripData?.original?.accepted_location_update_count,
      },
      {
        status: { text: "Computed", color: "#fdbc3d" },
        cost: singleTripData?.computed?.cost,
        distance: singleTripData?.computed?.distance,
        waiting_time: singleTripData?.computed?.waiting_time,
        waiting_cost: singleTripData?.computed?.waiting_cost,
        total_location_update_count:
          singleTripData?.computed?.total_location_update_count,
        sent_location_update_count:
          singleTripData?.computed?.sent_location_update_count,
        accepted_location_update_count:
          singleTripData?.computed?.accepted_location_update_count,
      },
      {
        status: { text: "Snapped", color: "#ed3f3c" },
        cost: singleTripData?.snapped?.cost,
        distance: singleTripData?.snapped?.distance,
        waiting_time: singleTripData?.snapped?.waiting_time,
        waiting_cost: singleTripData?.snapped?.waiting_cost,
        total_location_update_count:
          singleTripData?.snapped?.total_location_update_count,
        sent_location_update_count:
          singleTripData?.snapped?.sent_location_update_count,
        accepted_location_update_count:
          singleTripData?.snapped?.accepted_location_update_count,
      },
    ],
    [singleTripData]
  );

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight:
            Object.keys(singleTripData)?.length !== 0 ? "320px" : "600px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Ride Plus Trips
        </Typography>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Analytics Tool
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <IconButton
            edge="start"
            sx={{
              mr: 1,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowLeft sx={{ fontSize: 40 }} />
          </IconButton>
          <Button
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none",
              bgcolor: "#becfa9",
              color: "black",
              ":hover": {
                bgcolor: "#becfa9",
                color: "black",
              },
              width: "16rem",
              borderRadius: 0,
              p: 1,
            }}
          >
            Single Trip Analysis
          </Button>
        </Box>
        <SingleTripRequestForm />
      </Box>
      {Object.keys(singleTripData)?.length !== 0 && (
        <Box>
          <SingleTripDataSummary data={summary} />
          <Box
            sx={{
              display: "flex",
              height: "600px",
              flexDirection: "column",
              mb: 6,
            }}
          >
            <FormControl sx={{ my: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">
                Select to view what you wanted
              </FormLabel>
              <FormGroup>
                <Box sx={{ display: "flex" }}>
                  {singleTripData?.original?.poly_line !== "" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            original &&
                            singleTripData?.original?.poly_line !== ""
                          }
                          onChange={handleChange}
                          name="original"
                        />
                      }
                      label="Original"
                      hidden={singleTripData?.original?.poly_line === ""}
                    />
                  )}
                  {singleTripData?.computed?.poly_line !== "" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            computed &&
                            singleTripData?.computed?.poly_line !== ""
                          }
                          onChange={handleChange}
                          name="computed"
                        />
                      }
                      label="Computed"
                    />
                  )}
                  {singleTripData?.snapped?.poly_line !== "" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            snapped && singleTripData?.snapped?.poly_line !== ""
                          }
                          onChange={handleChange}
                          name="snapped"
                        />
                      }
                      label="Snapped"
                    />
                  )}
                </Box>
              </FormGroup>
            </FormControl>
            <CustomMapContainer selectedData={data} />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default SingleTrips;
