
const date = [
    { name: "is", value: "is" },
    { name: "is not", value: "not" },
    { name: "is after", value: "after" },
    { name: "is on or after", value: "onOrAfter" },
    { name: "is before", value: "before" },
    { name: "is on or before", value: "onOrBefore" },
    { name: "between", value: "between" },
    { name: "is empty", value: "empty" },
    { name: "is not empty", value: "notEmpty" },
]

export default date