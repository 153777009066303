import React, { createContext, useMemo, useState } from "react";

const StpDebugContext = createContext({});

export function StpDebugContextProvider({ children }) {
  const [bulkData, setBulkData] = useState(null);
  const [singleTripData, setSingleTripData] = useState({});
  const [filter, setFilter] = useState({
    column_field: "end_time",
    operator_value: "is",
    value: {
      value1: null,
      value2: null,
    },
  });
  const [tripId, setTripId] = useState("");

  const value = useMemo(
    () => ({
      filter,
      setFilter,
      tripId,
      setTripId,
      bulkData,
      setBulkData,
      singleTripData,
      setSingleTripData,
    }),
    [bulkData, filter, singleTripData, tripId]
  );

  return (
    <StpDebugContext.Provider value={value}>
      {children}
    </StpDebugContext.Provider>
  );
}

export default StpDebugContext;
