import React, { useEffect, useRef } from "react";
import fscreen from "fscreen";
import PolylineUtil from "polyline-encoded";
import L from "leaflet";
import "../assets/mapIcons.css";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";

const endMarkerIcon = (name) => {
  const icon =
    name === "original"
      ? "https://icons.iconarchive.com/icons/icons-land/vista-map-markers/256/Map-Marker-Marker-Outside-Chartreuse-icon.png"
      : name === "computed"
      ? "https://www.clipartmax.com/png/full/236-2368050_free-map-pin-icon-png-map-pin-yellow-png.png"
      : name === "snapped"
      ? "https://icons.iconarchive.com/icons/paomedia/small-n-flat/256/map-marker-icon.png"
      : "https://icons.iconarchive.com/icons/icons-land/vista-map-markers/256/Map-Marker-Marker-Outside-Chartreuse-icon.png";

  return new L.Icon({
    iconUrl: icon,
    iconSize: [35, 35],
    iconAnchor: [15, 20],
    popupAnchor: [0, -25],
  });
};

const startMarker = (name) => {
  const icon =
    name === "original"
      ? "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-green-icon.png"
      : name === "computed"
      ? "https://icons.iconarchive.com/icons/microsoft/fluentui-emoji-flat/256/Yellow-Circle-Flat-icon.png"
      : name === "snapped"
      ? "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-red-icon.png"
      : "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-green-icon.png";

  return new L.Icon({
    iconUrl: icon,
    iconSize: [18, 18],
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "#000",
    popupAnchor: [0, -25],
  });
};

export const FullScreenToggle = () => {
  const map = useMap();
  const container = map.getContainer();

  const handler = () => {
    if (fscreen.fullscreenElement === null) {
      fscreen.requestFullscreen(container);
    } else {
      fscreen.exitFullscreen();
    }
  };

  L.Control.FullScreenToggle = L.Control.extend({
    onAdd: function () {
      const control = document.querySelector("#fullscreen");

      if (!control) {
        const IconContainer = L.DomUtil.create("div", "fullscreen");
        IconContainer.setAttribute("id", "fullscreen");
        L.DomUtil.create("div", "fullscreen-inner", IconContainer);
        IconContainer.addEventListener("click", () => {
          if (fscreen.fullscreenEnabled) handler();
        });
        return IconContainer;
      }

      return control;
    },
    onRemove: function () {},
  });

  L.control.fullscreen = function (opt) {
    return new L.Control.FullScreenToggle(opt);
  };

  L.control.fullscreen({ position: "topleft" }).addTo(map);
};

const Polyline = ({ path, color, isSelected }) => {
  const map = useMap();
  const polylineRef = useRef(null);

  useEffect(() => {
    if (isSelected) {
      const decodedPolyline = PolylineUtil.decode(path);
      const polyline = L.polyline(decodedPolyline, {
        color: color,
        lineCap: "circle",
      });

      if (!polylineRef.current) {
        polyline.addTo(map);
        polylineRef.current = polyline;
      } else if (polylineRef.current !== polyline) {
        map.removeLayer(polylineRef.current);
        polyline.addTo(map);
        polylineRef.current = polyline;
      }

      map.fitBounds(polyline.getBounds());
    } else {
      if (polylineRef.current) {
        map.removeLayer(polylineRef.current);
        polylineRef.current = null;
      }
    }
  }, [color, isSelected, map, path]);

  return null; // Return null as the Polyline component doesn't render anything
};

function CustomMapContainer(props) {
  const { selectedData } = props;
  return (
    <MapContainer style={{ height: "100%", width: "100%" }}>
      {selectedData?.map((item, index) => (
        <React.Fragment key={index}>
          {item.status && item?.poly_line !== "" && (
            <>
              <Marker
                key={index}
                position={PolylineUtil.decode(item?.poly_line)[0]}
                icon={startMarker(item.name)}
              />
              <Marker
                position={
                  PolylineUtil.decode(item?.poly_line)[
                    PolylineUtil.decode(item?.poly_line)?.length - 1
                  ]
                }
                icon={endMarkerIcon(item.name)}
              />
            </>
          )}
          <Polyline
            path={item?.poly_line}
            color={item?.color}
            isSelected={item?.status && item?.poly_line !== ""}
          />
        </React.Fragment>
      ))}
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <FullScreenToggle />
    </MapContainer>
  );
}

export default CustomMapContainer;
