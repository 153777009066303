import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import StpDebugContext from "../context/stpDebugContextProvider";
import { useNavigate } from "react-router-dom";

function Home() {
  const { bulkData, singleTripData } = useContext(StpDebugContext);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight:
            bulkData?.length !== 0 || Object.keys(singleTripData)?.length !== 0
              ? "320px"
              : "600px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Ride Plus Trips
        </Typography>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Analytics Tool
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Button
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none",
              bgcolor: "#becfa9",
              color: "black",
              ":hover": {
                bgcolor: "#becfa9",
                color: "black",
              },
              width: "12rem",
              borderRadius: 0,
              p: 1,
              mb: 1,
            }}
            onClick={() => navigate("bulk-trips-analytics")}
          >
            Bulk Analysis
          </Button>
          <Button
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none",
              bgcolor: "#becfa9",
              color: "black",
              ":hover": {
                bgcolor: "#becfa9",
                color: "black",
              },
              width: "16rem",
              borderRadius: 0,
              p: 1,
            }}
            onClick={() => navigate("single-trip-analytics")}
          >
            Single Trip Analysis
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Home;
