import { useContext } from "react";
import { Box, FormControl, MenuItem, TextField } from "@mui/material";
import date from "../utils/filterOperatorValues";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import StpDebugContext from "../context/stpDebugContextProvider";
import { DateTime } from "luxon";

function DateFilterInput() {
  const { filter, setFilter } = useContext(StpDebugContext);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: { xs: "column", sm: "row" },
        gap: 2,
      }}
    >
      <FormControl
        sx={{
          justifyContent: "center",
          width: {
            xs: "100%",
            sm: filter.operator_value !== "between" ? "50%" : "43%",
          },
        }}
      >
        <TextField
          label="Operator"
          select
          InputLabelProps={{
            shrink: true,
          }}
          value={filter.operator_value}
          onChange={(e) => {
            setFilter((prev) => ({
              column_field: prev?.column_field,
              operator_value: e.target.value,
              value: prev?.value,
            }));
          }}
        >
          {date.map((item) => (
            <MenuItem key={item?.value} value={item?.value}>
              {item?.name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl
        fullWidth={filter.operator_value !== "between"}
        sx={{
          justifyContent: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateTimePicker
            disableMaskedInput
            label={filter?.operator_value === "between" ? "Value1" : "Value"}
            disableFuture
            value={
              filter.value.value1
                ? DateTime.fromISO(filter.value.value1)
                : undefined
            }
            onChange={(e) => {
              setFilter((prev) => ({
                column_field: prev?.column_field,
                operator_value: prev?.operator_value,
                value: { value1: e?.toString(), value2: prev?.value?.value2 },
              }));
            }}
            maxDate={
              filter.value.value2
                ? DateTime.fromISO(filter.value.value2) || new Date()
                : undefined
            }
          />
        </LocalizationProvider>
      </FormControl>
      {filter?.operator_value === "between" && (
        <FormControl
          sx={{
            justifyContent: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              disableMaskedInput
              label={"Value2"}
              disableFuture
              value={
                filter.value.value2
                  ? DateTime.fromISO(filter.value.value2)
                  : undefined
              }
              onChange={(e) => {
                setFilter((prev) => ({
                  column_field: prev?.column_field,
                  operator_value: prev?.operator_value,
                  value: { value1: prev?.value?.value1, value2: e?.toString() },
                }));
              }}
              minDate={
                filter.value.value1
                  ? DateTime.fromISO(filter.value.value1) || new Date()
                  : undefined
              }
            />
          </LocalizationProvider>
        </FormControl>
      )}
    </Box>
  );
}

export default DateFilterInput;
