import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useSingleData from "../hooks/useSingleData";
import { useContext, useState } from "react";
import StpDebugContext from "../context/stpDebugContextProvider";

// Analytics Request Form Component
function SingleTripRequestForm() {
  const { tripId, setTripId } = useContext(StpDebugContext);
  const [includeSnap, setIncludeSnap] = useState(false);

  // HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      min_speed: 5,
    },
  });

  // CUSTOM HOOK
  const { mutate: mutateSingle, isLoading } = useSingleData();

  // HELPERS and EVENT HANDLERS
  const onSubmit = (formData) => {
    const parsedData = Object.entries(formData)
      .filter(([key, value]) => value !== undefined)
      .reduce((parsed, [key, value]) => {
        parsed[key] = key === "include_snap" ? value : Number(value);
        return parsed;
      }, {});
    mutateSingle(parsedData);
  };

  // RENDER
  return (
    <Container maxWidth="md">
      <Box sx={{ width: "100%" }} component="form">
        <Grid
          container
          sx={{
            mt: 2,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                }}
                textAlign="end"
              >
                trip id
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                sx={{
                  flex: 1,
                }}
                defaultValue={tripId}
                onChange={(e) => setTripId(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography textAlign="end">min speed</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="min_speed"
                control={control}
                rules={{
                  required: "Minimum speed is required",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    type="number"
                    fullWidth
                    margin="normal"
                    autoComplete="min_speed"
                    error={!!errors?.min_speed}
                    helperText={
                      errors?.min_speed ? errors.min_speed.message : null
                    }
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography textAlign="end">max speed</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="max_speed"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    type="number"
                    margin="normal"
                    fullWidth
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography textAlign="end">accuracy limit</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="accuracy_limit"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} />
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography textAlign="end">max forward time gap</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="max_forward_time_gap"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} />
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography textAlign="end">includes snap</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: "#becfa9",
                      },
                    }}
                    checked={includeSnap}
                    onChange={(e) => setIncludeSnap(e.target.checked)}
                  />
                }
                {...register("include_snap")}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} />
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
          mb: 3,
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "#becfa9",
            ":hover": { bgcolor: "#becfa9" },
            textTransform: "none",
            fontWeight: "bold",
            width: 120,
            p: 1,
          }}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <Typography sx={{ color: "black" }}>Calculate</Typography>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "black",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </Box>
    </Container>
  );
}
export default SingleTripRequestForm;
