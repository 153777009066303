import React from "react";
import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import noData from "../assets/noDataFound.jpg";

function NoDataFound() {

  return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Avatar
            sx={{
              width: 300,
              height: 250,
              borderRadius: 0,
            }}
            alt="no_data"
            src={noData}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography
              sx={{
                display: "inline",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Please retry with other input!
            </Typography>
          </Box>
        </Box>
  );
}
export default NoDataFound;