import { useMutation } from "react-query";
import axios from "../utils/api/axios";
import { useContext } from "react";
import StpDebugContext from "../context/stpDebugContextProvider";
import { useSnackbar } from "notistack";

function useSingleData() {
    const { tripId, setSingleTripData, setBulkData } = useContext(StpDebugContext);

    const { enqueueSnackbar } = useSnackbar()

    return useMutation((formData) =>
        axios.post(`/debugs/stp_trips/${tripId}`, formData, {
            params: {
                per_page: -1
            }
        })
            .then((response) => response),
        {
            onSuccess: (data) => {
                setSingleTripData(data?.data?.data)
                setBulkData(null)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error[0]?.description
                const errorMessage = error.response?.data?.error?.message
                tripId === '' ? enqueueSnackbar("trip id is required", { variant: "error" }) : enqueueSnackbar(
                    fieldError || errorMessage || "Network Error",
                    { variant: "error" }
                )
            },
        }
    )
}
export default useSingleData